'use client';

import { ReactNode } from 'react';
import { SessionProvider } from 'next-auth/react';

type TSessionWrapperProps = {
  children: ReactNode;
};

const SessionWrapper = ({ children }: TSessionWrapperProps) => {
  return <SessionProvider>{children}</SessionProvider>;
};

export default SessionWrapper;
